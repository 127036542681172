@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    &::placeholder {
      @apply font-normal text-display-500;
    }

    @apply w-full font-normal rounded border-display-400 focus:ring-stone-500 focus:border-stone-500 hover:border-display-900 p-12;
  }

  html,
  body,
  #__next {
    @apply m-0 p-0 w-full h-full;
    scroll-behavior: smooth;
  }

  #__next {
    @apply relative;
  }

  * {
    @apply m-0 p-0 no-underline;
    scroll-behavior: smooth !important;
    box-sizing: border-box;
  }

  body {
    @apply flex flex-col;
  }

  button:disabled {
    @apply cursor-not-allowed;
  }

  button {
    @apply bg-none outline-none p-0;
  }

  button,
  a {
    @apply cursor-pointer border-none text-base font-normal;
  }

  a {
    @apply no-underline;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    @apply text-display-900  font-normal;
  }

  select {
    & > option {
      @apply h-32;
    }
  }

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .cc-window {
    overflow: inherit;
  }

  [type='radio'] {
    @apply w-20 h-20 ring-0 ring-display-0;
  }

  [type='radio']:checked:hover,
  [type='radio']:checked:focus {
    @apply bg-stone-500 ring-0 ring-display-0;
  }

  [type='radio']:hover,
  [type='radio']:focus {
    @apply ring-0 outline-none;
    --tw-ring-offset-width: 0;
  }

  [type='radio']:checked {
    @apply bg-stone-500 ring-0;
    background-image: url("data:image/svg+xml;utf8,<svg width='12' height='12' viewBox= '0 0 12 12' fill= 'none' xmlns='http://www.w3.org/2000/svg' ><path d= 'M10 3L4.5 8.5L2 6' stroke='white' stroke-width= '2' stroke-linecap= 'round' stroke-linejoin='round'/></svg>");
    background-size: auto;
  }

  input.input-error {
    @apply focus:ring-danger-500 focus:border-danger-500 border-danger-500;
  }

  input.input-error-unfocused {
    @apply ring-danger-500 border-danger-500 focus:ring-danger-500 focus:border-danger-500;
  }
}

@layer components {
  .input-error-message {
    @apply paragraph-12 text-danger-500;
  }

  .radio-ton {
    &:checked {
      @apply bg-display-0 border-2 border-ton-500 ring-0 ring-display-0;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6' cy='6' r='6' fill='%2300AD0C'/%3E%3C/svg%3E%0A");
      background-size: auto;
    }

    &:hover,
    &:focus {
      @apply bg-display-0 border-2 border-display-600 ring-0 ring-display-0;
    }

    &:checked:hover,
    &:checked:focus {
      @apply bg-display-0 border-2 border-ton-500 ring-0 ring-display-0;
    }
  }

  .overflow-x-clip {
    overflow-x: clip;
  }

  .container {
    @apply px-24 py-40 max-w-[1360px] mx-auto;
    @apply md:px-40 md:py-80;
  }

  .btn {
    @apply items-center rounded-4xl cursor-pointer inline-flex font-sharon font-medium justify-center outline-none;
    text-decoration: none;
    line-height: 24px;

    &:focus {
      outline: 0;
    }
  }
  .btn-transparent {
    @apply bg-transparent hover:bg-transparent focus:bg-transparent border-none shadow-none focus:outline-none;
  }

  .btn-primary {
    @apply text-display-0 bg-stone-500;

    &:hover {
      @apply bg-stone-600;
      box-shadow: 0px 0px 4px 2px rgba(66, 236, 154, 0.5);
    }

    &:focus {
      @apply bg-stone-500;
      box-shadow: 0px 0px 4px 2px rgba(66, 236, 154, 0.5);
    }

    &:active {
      @apply bg-stone-500;
      box-shadow: 0px 0px 8px 4px rgba(66, 236, 154, 0.5);
    }

    &:disabled {
      @apply text-display-0 bg-display-200 border border-solid border-display-200 shadow-none;
    }
  }

  .btn-ton-primary {
    @apply text-display-900 bg-ton-300;

    &:hover {
      @apply bg-ton-400;
      box-shadow: 0px 0px 4px 2px rgba(66, 236, 154, 0.5);
    }

    &:focus {
      @apply bg-ton-300;
      box-shadow: 0px 0px 4px 2px rgba(66, 236, 154, 0.5);
    }

    &:active {
      @apply bg-ton-500;
      box-shadow: 0px 0px 8px 4px rgba(66, 236, 154, 0.5);
    }

    &:disabled {
      @apply text-display-0 bg-display-200 border border-solid border-display-200 shadow-none;
    }
  }
  .btn-ton {
    @apply text-display-800 bg-lime-300;

    &:hover {
      @apply bg-lime-400;
      box-shadow: 0px 0px 4px 2px rgba(66, 236, 154, 0.5);
    }

    &:focus {
      @apply bg-lime-300;
      box-shadow: 0px 0px 4px 2px rgba(66, 236, 154, 0.5);
    }

    &:active {
      @apply bg-lime-500;
      box-shadow: 0px 0px 8px 4px rgba(66, 236, 154, 0.5);
    }

    &:disabled {
      @apply text-display-0 bg-display-200 border border-solid border-display-200 shadow-none;
    }
  }

  .btn-primary-neon {
    @apply text-display-900 bg-stone-300;

    &:hover {
      @apply bg-stone-400;
      box-shadow: 0px 0px 4px 2px rgba(66, 236, 154, 0.5);
    }

    &:focus {
      @apply bg-stone-300;
      box-shadow: 0px 0px 4px 2px rgba(66, 236, 154, 0.5);
    }

    &:active {
      @apply bg-stone-400;
      box-shadow: 0px 0px 8px 4px rgba(15, 204, 125, 0.3);
    }

    &:disabled {
      @apply text-display-0 bg-display-200 border border-solid border-display-200 shadow-none;
    }
  }

  .btn-secondary {
    @apply text-display-900 bg-display-0 border border-solid border-display-400;

    &:hover {
      @apply border border-solid border-stone-50 bg-stone-50;
      box-shadow: 0px 0px 4px 2px rgba(66, 236, 154, 0.5);
    }

    &:focus {
      @apply border border-solid border-display-400 bg-display-0;
      box-shadow: 0px 0px 4px 2px rgba(66, 236, 154, 0.5);
    }

    &:active {
      @apply bg-stone-300 border border-solid border-stone-400;
      box-shadow: 0px 0px 8px 4px rgba(66, 236, 154, 0.5);
    }

    &:disabled {
      @apply text-display-0 bg-display-200 border border-solid border-display-200 shadow-none;
    }
  }

  .btn-secondary-white {
    @apply text-white bg-opacity-20 bg-white border-white border-2 border-solid rounded-full;

    &:hover {
      @apply text-black bg-white;
    }

    &:focus {
      @apply right-2 ring-white outline-none text-black bg-white;
    }

    &:active {
      @apply bg-white border border-solid border-gray-50;
      box-shadow: 0px 0px 8px 4px #fff;
    }

    &:disabled {
      @apply text-display-0 bg-display-200 border border-solid border-display-200 shadow-none;
    }
  }

  .btn-secondary-ghost {
    @apply border border-solid border-display-300 bg-transparent text-display-0;
    @apply hover:border-stone-50 hover:bg-stone-50;
    @apply focus:border-display-300 focus:bg-display-0;
    @apply active:border-stone-300 active:bg-stone-300;

    &:hover,
    &:focus,
    &:active {
      @apply text-display-900;
      box-shadow: 0px 0px 4px 2px rgba(66, 236, 154, 0.5);
    }
  }

  .btn-primary-reverse {
    @apply text-display-900 bg-display-0 border border-solid border-display-0;

    &:hover {
      @apply bg-stone-50 border border-solid border-stone-50;
      box-shadow: 0px 0px 4px 2px rgba(66, 236, 154, 0.5);
    }

    &:focus {
      @apply bg-display-0 border border-solid border-display-300;
      box-shadow: 0px 0px 4px 2px rgba(66, 236, 154, 0.5);
    }

    &:active {
      @apply bg-stone-300 border border-solid border-stone-300;
      box-shadow: 0px 0px 8px 4px rgba(66, 236, 154, 0.5);
    }

    &:disabled {
      @apply text-display-0 bg-display-200 border border-solid border-display-200 shadow-none;
    }
  }

  .btn-ghost {
    @apply text-stone-900 bg-stone-50 border border-solid border-stone-50;

    &:hover {
      @apply bg-stone-50 border border-solid border-stone-50;
      box-shadow: 0px 0px 4px 2px rgba(66, 236, 154, 0.5);
    }

    &:focus {
      @apply bg-display-0 border border-solid border-display-0;
      box-shadow: 0px 0px 4px 2px rgba(66, 236, 154, 0.5);
    }

    &:active {
      @apply bg-stone-300 border border-solid border-stone-300;
      box-shadow: 0px 0px 8px 4px rgba(66, 236, 154, 0.5);
    }

    &:disabled {
      @apply text-display-300 bg-display-0 border border-solid border-display-0 shadow-none;
    }
  }

  .btn-faq {
    @apply relative ml-8 flex h-40 min-h-[40px] w-40 min-w-[40px] cursor-pointer
        items-center justify-center whitespace-normal rounded-[50%] border border-solid
        border-display-400 bg-display-0 p-0 font-semibold text-display-900 no-underline outline-none
        before:absolute before:h-[14px] before:w-2 before:bg-display-900 before:transition-all before:duration-[600ms]
        after:absolute after:h-2 after:w-[14px] after:bg-display-900
        hover:border hover:border-solid hover:border-stone-50 hover:bg-stone-50
        focus:border-display-400 focus:bg-display-0
        active:border-stone-400 active:bg-stone-300;

    &:hover,
    &:focus,
    &:active {
      box-shadow: 0px 0px 8px 4px rgba(66, 236, 154, 0.5);
    }
  }

  .btn-xsmall {
    height: 32px;
    @apply paragraph-14 px-24;
  }

  .btn-small {
    height: 40px;
    @apply paragraph-16 px-24;
  }

  .btn-regular {
    height: 48px;
    @apply paragraph-16 px-24;
  }

  .btn-large {
    height: 56px;
    @apply paragraph-16 px-24;
  }

  .btn-v2 {
    @apply styled_sm:w-max btn flex w-full cursor-pointer
    items-center justify-center whitespace-normal rounded-4xl
    py-16 px-32 font-semibold no-underline;

    outline: none;
  }

  .btn-v2-regular {
    @apply py-12 px-24;
  }

  .btn-v2-large {
    @apply py-16 px-32;
  }

  .paragraph-10 {
    font-size: 10px;
    @apply leading-1;
  }

  .paragraph-12 {
    @apply text-xs leading-2;
  }

  .paragraph-14 {
    @apply text-sm leading-3;
  }

  .paragraph-16 {
    @apply text-base leading-4;
  }

  .paragraph-18 {
    @apply text-lg;
    line-height: 26px;
  }

  .paragraph-20 {
    @apply text-xl;
    line-height: 28px;
  }

  .paragraph-24 {
    @apply text-2xl leading-6;
  }

  .paragraph-28 {
    @apply text-3xl leading-7;
  }

  .paragraph-30 {
    @apply text-4xl leading-8;
  }

  .paragraph-40 {
    @apply text-6xl leading-9 font-normal;
  }

  .heading-1 {
    @apply text-5xl font-bold;
    line-height: 44px;

    @apply md:text-7xl md:leading-10;
  }

  .heading-2 {
    @apply text-4xl font-bold;
    line-height: 38px;

    @apply md:text-6xl md:leading-9;
  }

  .heading-3 {
    @apply text-3xl leading-7 font-bold;
    @apply md:text-4xl md:leading-8;
  }

  .heading-4 {
    @apply text-2xl leading-6 font-bold;
    @apply md:text-3xl md:leading-7;
  }

  .heading-5 {
    @apply text-xl leading-5 font-bold;
    @apply md:text-2xl md:leading-6;
  }

  .heading-6 {
    @apply text-lg font-bold;
    line-height: 26px;

    @apply md:text-xl md:leading-5;
  }

  .w-min {
    width: min-content;
  }

  .w-max {
    width: max-content;
  }

  .w-fit {
    width: fit-content;
  }

  .h-min {
    height: min-content;
  }

  .h-max {
    height: max-content;
  }

  .h-fit {
    height: fit-content;
  }
}

.Dropdown-root {
  @apply cursor-pointer;

  & * {
    @apply text-display-900;
  }

  & .Dropdown-menu {
    & * {
      @apply text-display-900;
    }
  }

  & .Dropdown-control {
    padding-right: 30px;
    padding-left: 6px;
    border: 0;

    &:hover {
      box-shadow: none;
    }

    & .Dropdown-placeholder {
      text-align: left;
    }

    & .Dropdown-arrow {
      border: solid black;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      display: inline-block;
      padding: 5px;
      top: 10px;
      right: 8px;
    }
  }

  & .Dropdown-menu {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border: 1px solid #00c700;
    border-radius: 4px;

    & .Dropdown-option.is-selected {
      @apply text-display-900;
      @apply bg-ton-50;
    }

    & .Dropdown-option {
      @apply font-medium text-center;

      &:hover {
        @apply text-display-900;
        @apply bg-ton-100;
      }
    }
  }
}

.Dropdown-root.is-open {
  & .Dropdown-arrow {
    transform: rotate(225deg);
    top: 14px;
  }
}

div .react-select-form__control {
  @apply border-display-400 rounded;
  box-shadow: none;
  min-height: 48px;

  &:hover {
    @apply border-display-700;
  }

  & .react-select-form__indicator-separator {
    display: none;
  }

  & .react-select-form__value-container {
  }

  & .react-select-form__placeholder {
    @apply text-display-500;
    font-size: 16px;
    line-height: 24px;
  }

  & .react-select-form__menu {
    @apply border border-stone-500;
    box-shadow: none;
    outline: none;
  }

  & .react-select-form__option {
    @apply text-display-900;
  }
}

div .react-select-form__control--is-focused:hover,
div .react-select-form__control--menu-is-open {
  @apply border-stone-500 ring-stone-500;
}

div .react-select-form__option:hover,
div .react-select-form__option--is-focused {
  @apply bg-stone-100;
}

div .react-select-form__option--is-selected {
  @apply text-stone-500 bg-display-0;
}

.tooltip-rounded {
  --balloon-border-radius: 16px;
}

.rounded-green-text {
  @apply px-12 pb-[4px] w-[fit-content] h-[56px] leading-[58px] md:leading-[50px] lg:leading-[58px] rounded-[40px] border-[3px] border-stone-300;
}

video {
  clip-path: inset(2px 2px);
}

#modal {
  font-family: var(--font-sans);
}
